<script setup lang="ts">
  import { useGetAccount } from "@/api/account/account"
  import { GetSmartPayAsYouGoExecutionDateParams } from "@/api/model"
  import { useGetSmartPayAsYouGoExecutionDate } from "@/api/smart-pay-as-you-go/smart-pay-as-you-go"
  import { DateFormats, formatDate } from "@/lib/dateHelper"
  import { useGetAccountId } from "@/lib/useGetAccountId"
  import { useSmartPayAsYouGo } from "@/lib/useSmartPayAsYouGo"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import dayjs from "dayjs"
  import advancedFormat from "dayjs/plugin/advancedFormat"
  import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { z } from "zod"
  import { Button } from "@/components/ui/button"
  import { DatePicker } from "@/components/ui/inputs/date-picker"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  dayjs.extend(isSameOrAfter)
  dayjs.extend(advancedFormat)

  const accountIdQuery = useGetAccountId()
  const account = useGetAccount(accountIdQuery.accountId)
  const spaygEligibilityParams =
    computed<GetSmartPayAsYouGoExecutionDateParams>(() => ({
      billingAccountNumber: account.data.value?.accountNumber ?? "",
    }))
  const executionDateQuery = useGetSmartPayAsYouGoExecutionDate(
    accountIdQuery.accountId,
    spaygEligibilityParams,
  )

  const { switchDate } = useSmartPayAsYouGo()
  const executionDate = computed(() =>
    dayjs(executionDateQuery.data.value?.executionDate).add(
      executionDateQuery.data.value?.executionDate ? 1 : 6,
      "days",
    ),
  )

  const formSchema = computed(() =>
    toTypedSchema(
      z.object({
        switchDate: z
          .string({
            required_error: "This field is required. Please choose a date.",
            invalid_type_error: "This field is required. Please choose a date.",
          })
          .min(1, "This field is required. Please choose a date.")
          .refine(
            (val) => {
              const maxDate = dayjs().add(2, "month").endOf("day")
              return val && dayjs(val, DateFormats.default).isBefore(maxDate)
            },
            {
              message: "This date is too far in the future.",
            },
          )
          .refine(
            (val) => {
              return (
                val &&
                dayjs(val, DateFormats.default).isSameOrAfter(
                  dayjs(executionDate.value).startOf("day"),
                )
              )
            },
            {
              message: `You can only select a date after ${formatDate(executionDate.value, "default")} to proceed.`,
            },
          ),
      }),
    ),
  )

  const maxDate = formatDate(dayjs().add(2, "month"))

  const isDateDisabled = (date: string) =>
    [0, 6].includes(dayjs(date, DateFormats.default).day())

  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const { goNext } = useStepWizard()
  const onSubmit = handleSubmit(() => {
    goNext()
  })
</script>

<template>
  <StepWizardProgressCard title="Choose your switch date">
    <p class="mb-6">
      Pick a date that works for you. We'll need at least 4 working days from
      your chosen date to complete the switch.
    </p>

    <form>
      <DatePicker
        v-model="switchDate"
        name="switchDate"
        label="Date"
        class="max-w-[348px]"
        :show-label="false"
        :is-date-disabled="isDateDisabled"
        :max-value="maxDate"
        :supporting-labels="[
          { label: 'Please select a date within the next 2 months' },
        ]"
      />
    </form>

    <template #next_button>
      <Button variant="tonal" size="md" @click="onSubmit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
